import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/shared/services/header.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../auth/services/auth.service';
import { ManageSubCategoryService } from '../views/manage-sub-category/manage-sub-category.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit, OnDestroy {
  hide = true;
  hide1 = true;
  hide2 = true;

  message: string;
  currentUser: any;
  modalRef: BsModalRef;
  submitted: boolean = false;
  changed: boolean = false;
  languages:any;
  match: boolean = true;

  passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%&*])[\w !@#$%&*]/;

  passwordForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8),Validators.pattern(this.passwordRegex)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordRegex)]),
  })

  get p() {
    return this.passwordForm.controls;
  }

  constructor(
    private _header: HeaderService,
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private service: ManageSubCategoryService
  ) { }

  ngOnInit(): void {
    this.languages = localStorage.getItem('language');
    this._header.changePasswordHeading.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.getCategoryList(1,2);
  }
  ngOnDestroy() {
    this._header.changePasswordHeading.next(false);
  }

  // confirmation modal box
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  changePassword() {
    this.changed = true;
    if (this.passwordForm.invalid || this.passwordForm.pristine) return;
    this.changed = false;
    let data = {
      email:this.currentUser.email,
      oldPassword: this.passwordForm.value.oldPassword,
      newPassword: this.passwordForm.value.newPassword,
    };
    if (this.passwordForm.value.newPassword == this.passwordForm.value.confirmPassword) {
      this.match = true;
      this.authService.changePassword(data).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.passwordForm.controls['oldPassword'].reset();
          this.passwordForm.controls['newPassword'].reset();
          this.passwordForm.controls['confirmPassword'].reset();
          this.toastr.success(res.output);
          this.router.navigateByUrl('/dashboard');
        } else {
          this.toastr.error(res.error.message);
        }
      },(err)=>{
        this.toastr.error(err?.error?.output?.message);
      });
    } else {
      this.match = false;
    }
  }

  checkEnterPress(e:any,index:any){
    if(e.charCode==13){
      e.preventDefault();
      if(index==0){
        this.hide = this.hideShow(this.hide);
      }

      if(index==1){
        this.hide1 = this.hideShow(this.hide1);
      }

      if(index==2){
        this.hide2 = this.hideShow(this.hide2);
      }
      }
    }

    hideShow(e:any){
      return e ? false : true;
    }

    getCategoryList(page:any,pageLimit:any){
      this.service.categoryList({page:page,pageLimit:pageLimit,type:"category"}).subscribe((res:any) => {
      })
    }
}
