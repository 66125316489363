import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'share-happy-admin';
  status = 'ONLINE';
  isConnected = true;

  currentUser:any;

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
  }
  constructor(
    private connectionService: ConnectionService,
    private toastrService: ToastrService,
    private authService: AuthService
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      }
      else {
        this.status = "OFFLINE";
        this.toastrService.error('Connection Lost');
      }
    })
  }
}
