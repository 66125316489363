<div class="sidebar" (keydown)="disabledTab($event)">
  <div class="logo"><img src="../../../assets/images/shs_black.svg" alt="logo"></div>
  <div class="header-links">

    <a routerLink="/dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Manage Business.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/Manage_Business_White.svg" alt="" height="24" width="24">
    </span>Dashboard</a>

    <a routerLink="/manage-categories" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Manage Categories.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/Manage_Category_White.svg" alt="" height="24" width="24">
    </span>Manage Categories</a>


    <a routerLink="/manage-sub-categories" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Manage Categories.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/Manage_Category_White.svg" alt="" height="24" width="24">
    </span>Manage Sub Categories</a>


    <a routerLink="/refund-request" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Manage Categories.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/Manage_Category_White.svg" alt="" height="24" width="24">
    </span>Refund Requests</a>
    

    <a routerLink="/report-products" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Manage Transactions.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/Manage_Transaction_White.svg" alt="" height="24" width="24">
    </span>Report Products</a> 

    <a routerLink="/notifications" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/notifications.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/Notifications_White.svg" alt="" height="24" width="24">
    </span>Broadcast Notifications</a> 

    <a routerLink="/edit-profile" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Chnage Password Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/Change Password.svg" alt="" height="24" width="24">
    </span>Change Password</a>


    <a routerLinkActive="active" (click)="openModal(template)" id="logout">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Log Out Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Black/Log Out Black.svg" alt="" height="24" width="24">
    </span>Logout</a>

  </div>
</div>


    <!-- Logout fonfirm -->
<ng-template #template>
  <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
      <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
      <p>Are you sure you <br>want to logout</p>
      <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
          <button class="btn btn-blue" (click)="confirm()" >Yes</button>
      </div>
  </div>
</ng-template>