import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  manageContractorHeading = new BehaviorSubject(false);
  manageFeedsHeading = new BehaviorSubject(false);
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading =  new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  refundRequestHeading = new BehaviorSubject(false);
  manageCategoriesHeading = new BehaviorSubject(false);
  manageSubCategoriesHeading = new BehaviorSubject(false);
  manageTransactionHeading = new BehaviorSubject(false);
  reportProductsHeading = new BehaviorSubject(false);
  notificationHeading= new BehaviorSubject(false);
}
