import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "refund-request",
        loadChildren: () =>
          import("./views/refund-request/refund-request.module").then((m) => m.RefundRequestModule),
      },

      {
        path: "dashboard",
        loadChildren: () =>
        import("../app/dashboard/dashboard.module").then(
          (m) => m.DashboardModule
        )
      },

      {
        path: "manage-categories",
        loadChildren: () =>
        import("../app/views/manage-category/manage-category.module").then(
          (m) => m.ManageCategoryModule
        )
      },

      {
        path: "manage-sub-categories",
        loadChildren: () =>
        import("../app/views/manage-sub-category/manage-sub-category.module").then(
          (m) => m.ManageSubCategoryModule
        )
      },

      {
        path: "manage-transactions",
        loadChildren: () =>
        import("../app/views/manage-transaction/manage-transaction.module").then(
          (m) => m.ManageTransactionModule
        )
      },


      {
        path: "report-products",
        loadChildren: () =>
        import("../app/views/report-products/report-products.module").then(
          (m) => m.ReportProductsModule
        )
      },

      {
        path: "notifications",
        loadChildren: () =>
        import("../app/views/notification/notification.module").then(
          (m) => m.NotificationsModule
        )
      },

    ],
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
