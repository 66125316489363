

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class NotificationService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getNotificationList(data: any) {
        return this.http.get(this.url + `broadcast/listBroadCast?userType=${data?.userType}&page=${data?.page}&limit=${data?.limit}`);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'broadcast/sendBroadCast', data);
    }

}