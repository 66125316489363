import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getAnalytics(data:any){
        return this.http.post(this.url + "home/dashboard/admin",data);
    }

    getRentersList(page:any,pageLimit:any){
        return this.http.get(this.url + `home/dashboard/rentersCount?page=${page}&pageSize=${pageLimit}`);
    }

    getLentersList(page:any,pageLimit:any){
        return this.http.get(this.url + `home/dashboard/lentersCount?page=${page}&pageSize=${pageLimit}`);
    }

    getProductRequestList(page:any,pageLimit:any){
        return this.http.get(this.url + `home/dashboard/requestedProductCount?page=${page}&pageSize=${pageLimit}`);
    }

    getMostViewProducts(page:any,pageLimit:any,subcategory:any, region:any){
        return this.http.get(this.url + `home/dashboard/mostViewedProducts?page=${page}&pageSize=${pageLimit}&subcategory=${subcategory}&region=${region}`);
    }

    getSingleProduct(data:any){
        return this.http.post(this.url + 'product/get', data)
    }

    subCategoryList(){
        return this.http.get(this.url + 'product/subcategoryList?page=1&pageSize=100')
    }

    regionList(){
        return this.http.get(this.url + 'product/regionList')
    }

}