import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageCategory {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    uploadImage(data:any){
        return this.http.post(this.url + 'category/upload', data);
    }

    addCategory(data:any){
        return this.http.post(this.url + 'category/add',data);
    }

    categoryList(data:any){
        return this.http.post(this.url + `category/list`,data);
    }

    updateCategory(data:any){
        return this.http.post(this.url + 'category/edit',data);
    }


    deleteCategory(data:any){
        return this.http.post(this.url + 'category/delete',data);
    }


}