import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedRoutingModule } from './shared-routing.module';

// ngx bootstrap
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LayoutComponent } from './layout/layout.component';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LocalDatePipe } from './pipes/local-date.pipe';



@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    LayoutComponent,
    LocalDatePipe
  ],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    SharedRoutingModule,
    HttpClientModule,
    ModalModule.forRoot()
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    LayoutComponent,
    LocalDatePipe
  ],
})
export class SharedModule { }
