import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class RefundRequestService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getRefundList(page:any,pageLimit:any){
        return this.http.get(this.url + `home/dashboard/refundRequestedCount?page=${page}&pageSize=${pageLimit}`);
    }

    getRefundById(data:any){
        return this.http.post(this.url + 'refund/get', data);
    }

    updateRefundRequest(data:any){
        return this.http.post(this.url + 'refund/admin/update', data)
    }

    
}